.nav-hidden {
    display: none;
}

.nav-open {
    display: block;
    --tw-text-opacity: 1;
    color: rgb(148 163 184/var(--tw-text-opacity));
    line-height: 1.625;
    --tw-bg-opacity: 1;
    margin: 0;
}

.nav-ul {
    width: max-content;
    margin-top: 4rem;
    margin-bottom: 0;
    padding: 0;
    display: block;

}

.nav-ul li {
    display: list-item;
    text-align: -webkit-match-parent;
    list-style: none;
    cursor: pointer;
}

.nav-item-normal {
    line-height: 1.625;
    --tw-text-opacity: 1;
    color: rgb(148 163 184/var(--tw-text-opacity));
    --tw-bg-opacity: 1;
}

.hovered-nav-item {
    
}

.flex-nav-center {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: inherit;
}

.nav-line-elongate {
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
    width: 4rem;
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240/var(--tw-bg-opacity));
    height: 1px;
    margin-right: 1rem;
}

.nav-line-normal {
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
    --tw-bg-opacity: 1;
    background-color: rgb(71 85 105/var(--tw-bg-opacity));
    width: 2rem;
    height: 1px;
    margin-right: 1rem;
}

.nav-text-elongate {
    --tw-text-opacity: 1;
    color: rgb(226 232 240/var(--tw-text-opacity));
    letter-spacing: .1em;
    text-transform: uppercase;
    font-weight: 700;
    font-size: .75rem;
    line-height: 1rem;
}

.nav-text-normal {
    --tw-text-opacity: 1;
    color: rgb(100 116 139/var(--tw-text-opacity));
    letter-spacing: .1em;
    text-transform: uppercase;
    font-weight: 700;
    font-size: .75rem;
    line-height: 1rem;

}