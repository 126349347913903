body {
  margin: 0;
  padding: 0;
  --tw-bg-opacity: 1;
    background-color: rgb(15 23 42/var(--tw-bg-opacity));
    display: block;
    --tw-text-opacity: 1;
    color: rgb(148 163 184/var(--tw-text-opacity));
    line-height: 1.625;
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42/var(--tw-bg-opacity));
    tab-size: 4;
    font-family: var(--font-inter),ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-feature-settings: "ss03","cv02","cv11";
    font-variation-settings: normal;
}

body::selection {
  --tw-text-opacity: 1;
  color: rgb(19 78 74/var(--tw-text-opacity));
  background-color: rgb(94 234 212/var(--tw-bg-opacity));

}

*, :after, :before {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:root {
  --deg: 1;
  --x: -50%;
  --y: -50%;
}